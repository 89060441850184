body {
  font-size: 17px;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

li {
  font-size: 17px;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

mjx-container {
  display: inline-grid;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100%;
}

/* https://stackoverflow.com/a/2618980/4332314 */

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}