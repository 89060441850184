.interactive-code {
    padding: 10px;
}

.interactive-code-editor-with-viz {
    width: 50%;
    min-height: 100px;
}

.interactive-code-editor-without-viz {
    width: 100%;
    height: 100px;
}

.interactive-code-viz {
    width: 50%;
}

@media (max-width: 1280px) {
    .interactive-code-editor-with-viz {
        width: 100%;
        min-height: 300px;
    }

    .interactive-code-viz {
        width: 100%;
    }
}

.interactive-code-output {
    width: 100%;
    height: 100px;
}